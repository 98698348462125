import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
*, *:before, *:after {
	box-sizing: border-box;
	scroll-behavior: smooth;
}
body {
	font-family: Roboto, sans-serif;
}
a {
	text-decoration: none;
	color: inherit;
}
h1 {
	font-size: 4rem;
    padding: 2rem;
	@media (max-width: 600px) {
		font-size: 3rem
	}
}
h2 {
	font-size: 3rem;
    padding: 1.5rem;
	@media (max-width: 600px) {
		font-size: 2rem;
	}
}
h3 {
	font-size: 2rem;
    padding: 0.5rem;
	@media (max-width: 600px) {
		font-size: 1.5rem;
	}
}
h4 {
	font-size: 1.3rem;
	line-height: 2rem;
    padding: 0.5rem;
	@media (max-width: 600px) {
		font-size: 1rem;
	}
}
p {
	line-height: 1.5rem;
	font-size: 1rem;
    padding: 0.5rem;
}
section, footer {
	margin-top: 2rem;
	padding: 0 5vw;
	@media (max-width: 600px) {
		margin-top: 1rem;
        padding: 0 1vw;
    }
}
.section-title {
	color: #3f51b5;
    font-weight: bold;
}
`;

export default GlobalStyle;
